// src/components/PanelFull.js

import React from 'react';
import './PanelFull.css';
import config from '../pages/config';

const PanelFull = ({ panel, viewAsAdmin, onEdit, onDelete }) => {
  // Construct the image URL based on your static folder setup
  const imageUrl = panel.image ? `${config.apiBaseUrl}/panel_images/${panel._id}.jpg` : '';

  const handlePanelClick = () => {
    if (panel.link) {
      // Open the link in a new tab
      window.open(panel.link, '_blank');
    }
  };

  return (
    <div className="panel-full" onClick={handlePanelClick}>
      {imageUrl && (
        <div className="panel-image-container">
          <img src={imageUrl} alt={`${panel.title} image`} className="panel-image" />
          <div className="panel-text-overlay">
            <h1 className="panel-title">{panel.title}</h1>
            {panel.description && (
              <p className="panel-description">{panel.description}</p>
            )}
          </div>
        </div>
      )}
      {viewAsAdmin && (
        <div className="admin-actions" onClick={(e) => e.stopPropagation()}>
          <button onClick={(e) => { e.stopPropagation(); onEdit(panel); }}>Edit</button>
          <button onClick={(e) => { e.stopPropagation(); onDelete(panel._id); }}>Delete</button>
        </div>
      )}
    </div>
  );
};

export default PanelFull;
