// src/components/Navbar.js

import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png'; // Adjust the path to your logo image
import './Navbar.css'; // Import your CSS file

const Navbar = ({ isLoggedIn, accountName }) => {
  return (
    <nav className="navbar">
      {/* Left Section: Logo */}
      <div className="navbar-logo">
        <NavLink to="/">
          <img src={logo} alt="Logo" />
        </NavLink>
      </div>

      {/* Center Section: Navigation Links */}
      <div className="navbar-center">
        <NavLink
          to="/"
          className="nav-link"
          activeclassname="active"
        >
          Home
        </NavLink>
        <NavLink
          to="/library"
          className="nav-link"
          activeclassname="active"
        >
          Your Library
        </NavLink>
        <NavLink
          to="/infos"
          className="nav-link"
          activeclassname="active"
        >
          Infos
        </NavLink>
      </div>

      {/* Right Section: Login/Account */}
      <div className="navbar-account">
        <NavLink to="/login" className="nav-link">
          {isLoggedIn ? accountName : 'Login'}
        </NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
