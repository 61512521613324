// src/components/ErrorMessage.js
import React from 'react';
import './ErrorMessage.css'; // Import corresponding CSS for styling

function ErrorMessage({ title, message, actions }) {
  return (
    <div className="error-message-container">
      <h2>{title}</h2>
      <p>{message}</p>
      {actions && <div className="actions">{actions}</div>}
    </div>
  );
}

export default ErrorMessage;
