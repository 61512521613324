// src/pages/HomePage.js

import React, { useEffect, useState, useContext } from 'react';
import Navbar from '../components/Navbar';
import ApiService from '../services/ApiService';
import PanelService from '../services/PanelService';
import PanelFull from '../components/PanelFull';
import '../styles/Home.css'; // Import page-specific styles
import Loader from '../components/Loader'; // Import Loader component
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import { AuthContext } from '../services/AuthContext';

const HomePage = () => {
  const [panels, setPanels] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [viewAsAdmin, setViewAsAdmin] = useState(false);
  const [newPanel, setNewPanel] = useState({
    title: '',
    description: '',
    link: '',
    index: 0,
  });
  const [editingPanel, setEditingPanel] = useState(null);
  const [error, setError] = useState(null);
  const [imageFile, setImageFile] = useState(null); // State to hold the selected image file
  const [loading, setLoading] = useState(false); // Loading state
  const { isAuthenticated, userData } = useContext(AuthContext);

  useEffect(() => {
    const fetchPanelsAndUser = async () => {
      setLoading(true); // Start loading
      try {
        const panelsData = await PanelService.getPanels();
        // Sort panels by index
        const sortedPanels = panelsData.sort((a, b) => a.index - b.index);
        setPanels(sortedPanels);

        // Fetch user data to check admin status
        if (isAuthenticated === true) {
          setIsAdmin(userData.isAdmin);
          setAccountName(userData.fullName);
        }

      } catch (error) {
        console.error('Error fetching panels or user data:', error);
        setError('Failed to fetch panels or user data.');
        toast.error('Failed to fetch panels or user data.');
      } finally {
        setLoading(false); // Stop loading
      }
    };
    fetchPanelsAndUser();
  }, [isAuthenticated, userData]);

  const handleCreatePanel = async () => {
    setLoading(true); // Start loading
    try {
      // Validate required fields
      if (!newPanel.title.trim() || !newPanel.link.trim()) {
        setError('Title and Link are required.');
        toast.error('Title and Link are required.');
        setLoading(false);
        return;
      }

      const panelData = { ...newPanel };
      const createdPanel = await PanelService.createPanel(panelData);
      setPanels((prevPanels) =>
        [...prevPanels, createdPanel].sort((a, b) => a.index - b.index)
      );

      // If an image is selected, upload it
      if (imageFile) {
        await PanelService.uploadPanelImage(createdPanel._id, imageFile);
        // Refresh panels to get the updated image path
        const updatedPanels = await PanelService.getPanels();
        setPanels(updatedPanels.sort((a, b) => a.index - b.index));
        setImageFile(null); // Reset image file state
        toast.success('Panel image uploaded successfully.');
      }

      // Reset form
      setNewPanel({
        title: '',
        description: '',
        link: '',
        index: 0,
      });
      setError(null); // Clear any previous errors
      toast.success('Panel created successfully.');
    } catch (error) {
      console.error('Error creating panel:', error);
      setError('Failed to create panel.');
      toast.error('Failed to create panel.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleEditPanel = async () => {
    if (editingPanel) {
      setLoading(true); // Start loading
      try {
        // Validate required fields
        if (!editingPanel.title.trim() || !editingPanel.link.trim()) {
          setError('Title and Link are required.');
          toast.error('Title and Link are required.');
          setLoading(false);
          return;
        }

        const updatedPanel = await PanelService.updatePanel(
          editingPanel._id,
          editingPanel
        );
        setPanels((prevPanels) =>
          prevPanels
            .map((panel) =>
              panel._id === updatedPanel._id ? updatedPanel : panel
            )
            .sort((a, b) => a.index - b.index)
        );

        // If a new image is selected, upload it
        if (imageFile) {
          await PanelService.uploadPanelImage(updatedPanel._id, imageFile);
          // Refresh panels to get the updated image path
          const updatedPanels = await PanelService.getPanels();
          setPanels(updatedPanels.sort((a, b) => a.index - b.index));
          setImageFile(null); // Reset image file state
          toast.success('Panel image uploaded successfully.');
        }

        setEditingPanel(null);
        setError(null); // Clear any previous errors
        toast.success('Panel updated successfully.');
      } catch (error) {
        console.error('Error updating panel:', error);
        setError('Failed to update panel.');
        toast.error('Failed to update panel.');
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  const handleDeletePanel = async (id) => {
    if (window.confirm('Are you sure you want to delete this panel?')) {
      setLoading(true); // Start loading
      try {
        await PanelService.deletePanel(id);
        setPanels((prevPanels) =>
          prevPanels.filter((panel) => panel._id !== id)
        );
        setError(null); // Clear any previous errors
        toast.success('Panel deleted successfully.');
      } catch (error) {
        console.error('Error deleting panel:', error);
        setError('Failed to delete panel.');
        toast.error('Failed to delete panel.');
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const renderPanels = () =>
    panels.map((panel) => (
      <PanelFull
        key={panel._id}
        panel={panel}
        viewAsAdmin={viewAsAdmin}
        onEdit={setEditingPanel}
        onDelete={handleDeletePanel}
      />
    ));

  return (
    <>
      <Navbar isLoggedIn={!!accountName} accountName={accountName} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isAdmin && (
        <div className="admin-toggle-container">
          <button
            onClick={() => setViewAsAdmin(!viewAsAdmin)}
            aria-label={viewAsAdmin ? 'Switch to User View' : 'Switch to Admin View'}
          >
            {viewAsAdmin ? 'View as User' : 'View as Admin'}
          </button>
        </div>
      )}
      <div className="home-page-container">
        {loading && <Loader />} {/* Display Loader when loading */}
        {renderPanels()}
      </div>
      {viewAsAdmin && (
        <div className={`admin-panel-form ${viewAsAdmin ? '' : 'hidden'}`}>
          <h3>{editingPanel ? 'Edit Panel' : 'Create Panel'}</h3>
          <input
            type="text"
            placeholder="Title"
            value={editingPanel ? editingPanel.title : newPanel.title}
            onChange={(e) =>
              editingPanel
                ? setEditingPanel({ ...editingPanel, title: e.target.value })
                : setNewPanel({ ...newPanel, title: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Description"
            value={editingPanel ? editingPanel.description : newPanel.description}
            onChange={(e) =>
              editingPanel
                ? setEditingPanel({ ...editingPanel, description: e.target.value })
                : setNewPanel({ ...newPanel, description: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Link"
            value={editingPanel ? editingPanel.link : newPanel.link}
            onChange={(e) =>
              editingPanel
                ? setEditingPanel({ ...editingPanel, link: e.target.value })
                : setNewPanel({ ...newPanel, link: e.target.value })
            }
          />
          {/* Index Field */}
          <label>
            Index
            <input
              type="number"
              min="0"
              value={editingPanel ? editingPanel.index : newPanel.index}
              onChange={(e) =>
                editingPanel
                  ? setEditingPanel({
                      ...editingPanel,
                      index: parseInt(e.target.value, 10),
                    })
                  : setNewPanel({ ...newPanel, index: parseInt(e.target.value, 10) })
              }
            />
          </label>
          <label>
            Image Upload
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleImageChange}
            />
          </label>
          {imageFile && (
            <div className="image-preview">
              <img src={URL.createObjectURL(imageFile)} alt="Preview" />
            </div>
          )}
          <button onClick={editingPanel ? handleEditPanel : handleCreatePanel}>
            {editingPanel ? 'Save Changes' : 'Create Panel'}
          </button>
          {editingPanel && (
            <button onClick={() => setEditingPanel(null)}>Cancel</button>
          )}
          {error && <p className="error-message">{error}</p>}
        </div>
      )}
    </>
  );
};

export default HomePage;
