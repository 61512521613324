// src/components/InstructionsModal.js
import React from 'react';
import './InstructionsModal.css'; // Import corresponding CSS for styling

function InstructionsModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Resolve Authentication Issues</h2>
        <ol>
          <li>
            <strong>Enable Cookies:</strong>
            <p>
              Ensure that your browser is set to accept cookies. Here's how you can do it:
            </p>
            <ul>
              <li><strong>Firefox:</strong> Go to <em>Settings</em> &gt; <em>Privacy & Security</em> &gt; <em>Cookies and Site Data</em>, and select <em>Standard</em> or <em>Custom</em> with cookies allowed.</li>
              <li><strong>Chrome:</strong> Go to <em>Settings</em> &gt; <em>Privacy and security</em> &gt; <em>Cookies and other site data</em>, and ensure that cookies are allowed.</li>
            </ul>
          </li>
          <li>
            <strong>Disable Interfering Extensions:</strong>
            <p>
              Some browser extensions may block cookies or essential network requests. Try disabling extensions like ad blockers or privacy tools temporarily and attempt to log in again.
            </p>
            <ul>
              <li><strong>Firefox:</strong> Go to <em>Menu</em> &gt; <em>Add-ons and Themes</em> &gt; <em>Extensions</em>, and disable extensions as needed.</li>
              <li><strong>Chrome:</strong> Go to <em>Menu</em> &gt; <em>More tools</em> &gt; <em>Extensions</em>, and disable extensions as needed.</li>
            </ul>
          </li>
          <li>
            <strong>Reload and Try Again:</strong>
            <p>
              After making the above changes, reload the page and attempt to log in again.
            </p>
          </li>
        </ol>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default InstructionsModal;
