// src/pages/library/PostsList.js
import React from 'react';
import PostCard from './PostCard';
import ApiService from '../../services/ApiService';
import Loader from '../../components/Loader'; // Import Loader
import { toast } from 'react-toastify'; // Import toast

const PostsList = ({
  posts,
  loading,
  error,
  postsPerLine,
  viewAsAdmin,
  handleToggleEnabled,
  setPosts,
  availableTiers,
}) => {
  const postWidth = `${100 / postsPerLine - 6 / postsPerLine}%`;

  if (loading) {
    return <Loader />; // Use Loader component
  }

  if (error) {
    toast.error('Error loading posts.');
    return <div>Error loading posts.</div>;
  }

  const updatePostsOrder = async (updatedPost, newIndex) => {
    try {
      // Clone posts array and find the post to be moved
      const updatedPosts = [...posts];
      const postIndex = updatedPosts.findIndex((p) => p.postId === updatedPost.postId);

      // Remove the updated post from its original position
      updatedPosts.splice(postIndex, 1);
      // Insert it at the new index
      updatedPosts.splice(newIndex, 0, updatedPost);
      // Determine the new order_date for the moved post
      const nextPost = updatedPosts[newIndex + 1];
      const beforePost = updatedPosts[newIndex - 1];
      let newOrderDate;
      if (nextPost) {
        if (nextPost.order_date) {
          newOrderDate = new Date(new Date(nextPost.order_date).getTime() + 60000); // Add 1 minute
        } else {
          newOrderDate = new Date(new Date(nextPost.published_at).getTime() + 60000);
        }
      } else {
        // If there is no next post, set order_date to beforePost - 60000
        if (beforePost.order_date) {
          newOrderDate = new Date(new Date(beforePost.order_date).getTime() - 60000);
        } else {
          newOrderDate = new Date(new Date(beforePost.published_at).getTime() - 60000);
        }
      }
      // Update the moved post's order_date
      updatedPost.order_date = newOrderDate;
      // Update state with the reordered posts
      setPosts(updatedPosts);
      // Update the backend with the new order_date of the moved post
      await ApiService.updateOrder(updatedPost.postId, updatedPost.order_date);

      toast.success('Post index updated successfully.');
    } catch (error) {
      console.error('Error updating post order:', error);
      toast.error('Failed to update post order');
    }
  };

  return (
    <div
      style={{
        padding: '100px 20px 20px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '20px',
      }}
    >
      {posts.map((post, index) => (
        <PostCard
          key={post.postId}
          post={post}
          postWidth={postWidth}
          viewAsAdmin={viewAsAdmin}
          handleToggleEnabled={handleToggleEnabled}
          updatePostsOrder={updatePostsOrder}
          index={index}
          availableTiers={availableTiers} // Pass availableTiers down
        />
      ))}
    </div>
  );
};

export default PostsList;
