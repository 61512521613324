// src/pages/infos/paneldata.js
import kofiLogo from '../../assets/kofi_logo.png';
import discordLogo from '../../assets/discord_logo.png';
import patreonLogo from '../../assets/patreon_logo.png';

const panelData = [
    { 
      title: 'About Me', 
      content: (
        <div>
            <p><strong><u>Work :</u></strong></p>
            <ul>
            <p>Im creating 3d designs from scratch. I make them fitting xiv and im aiming mostly at giving a refreshing, pleasing and easy experience to all my supporters through my creations.</p>
            <p>Im mostly focusing on clothes and accessories, and some body sculpt. I focus mainly on females, but I sometimes do males and transgenders</p>
            <p>Im always looking to learn new things and work on new things. Knowledge is something i value highly.</p>
            </ul>
            <p><strong><u>My self :</u></strong></p>
            <ul>
            <p>Smiling ... Happyness, those are the two first word that could come to mind if i had to speak about me~</p>
            <p>I'am always trying to take the best part of everything, to see the glass half full and not half empty.</p>
            <p>I believe that by making others happy, they will always bring it back to me !</p>
            </ul>
        </div>
      ),
    },
    { 
      title: 'Permissions', 
      content: (
        <div>
            <p><strong><u>Paid Content :</u></strong></p>
            <ul>
            <li>Early access &amp; paid mods must stay private. Do not share, even to friends, link to my pages so they can get it themself. Personal edits are fine indeed. As long as they stay private and personal.</li>
            <li>Do not port to other games.</li>
            <li>Do not use my assets for creations/edits/upscales/whatever for private commissions, unless you asked me first and I personally gave you persmission.
            (If you want any kind of modifications made to my stuff, you can directly ask me, usually im up to do those ♥)</li>
            <li>You can use my private/paid stuff to do whatever you want as long as it stays private and for you only.</li>
            </ul>
            <p><strong><u>Free Content :</u></strong></p>
            <ul>
            <li>Do not port to other games, unless you asked me~</li>
            <li>Other than that, do whatever you want as long as you credit me ♥</li>
            <li>If you do any kind of rescale, modification, texture edit, please credit me and link to the original post as a required file ! And you can still send me a message if you use any of my mods for creating something, I will be happy to know-</li>
            <li>You&#39;re free to use my PUBLIC assets for private commissions.</li>
            <li>Reminder that im usually up to do commissions on my own stuff, including upscaling, so reach me out if you are interested in any of those ! I will most likely provide you the best result as i have all the work files of it</li>
            </ul>
        </div>
      ),
    },
    {
      title: 'Commissions',
      content: (
        <div>
            <p><strong><u>How to commission something ?</u></strong><u>:</u> You must join my discord and open a commission ticket ! We will talk about all the details there<strong>&nbsp;</strong></p>
            <p><strong><u>Options</u></strong><u>:</u> If you commission something, you most likely will get the usual amount of options I offer on my stuff ! (A lot)<strong>&nbsp;</strong></p>
            <p><strong><u>Name&amp;Previews</u></strong>: By commissionning, if you would like to, you also get to choose the name and be in the previews !<strong><u></u></strong></p>
            <p><strong><u>Privacy</u></strong><u>:</u> I usually publish commissions for my patrons + maybe on Kofi.</p>
            <p><strong><u>Private Commissions</u></strong><u>:</u> If you want it to remain private, I may decline.</p>
            <p><strong><u>Price</u></strong><u>:</u> Prices are based on the request. I&rsquo;ll give a fair quote.</p>
            <p><u><strong>When(ETA)</strong></u><u>:</u> Depends on how much commissions im having when you ask. Can be a week up to month+. You will know as soon as you ask me !</p>
            <p><strong><u>Refusing</u></strong><u>:</u> I reserve the right to refuse requests that aren&rsquo;t within my field or comfort zone.</p>
            <div className="button-container">
          <a
            href="https://discord.gg/applenzo"
            target="_blank"
            rel="noopener noreferrer"
            className="info-button"
          >
            <img src={discordLogo} alt="Discord Logo" />
            Join my Discord
          </a>

          <a
          href="https://discord.com/channels/981126232097832981/983057731231551508"
          target="_blank"
          rel="noopener noreferrer"
          className="info-button"
        >
          <img src={discordLogo} alt="Discord Logo" />
          Commission Form (In my discord)
        </a>
        </div>
        </div>
      ),
    },
    { 
      title: 'Kofi Commissions (Collars/Upscales...)', 
      content: (
        <div>
            <p><strong><u>Ko-fi Commissions</u></strong>: Custom Chokers, Necklaces, Tags, Collars, and edits. Check it out before using the commission form!</p>
            <p><strong><u>Upscaling</u></strong>: My Upscaling commission is open on Kofi. I do upscaling only of my own work.</p>
            <div className="button-container">
          <a
            href="https://ko-fi.com/holowolfy/commissions"
            target="_blank"
            rel="noopener noreferrer"
            className="info-button"
          >
            <img src={kofiLogo} alt="Kofi Logo" />
            Check on Kofi Commissions
          </a>
        </div>
        </div>
      ),
    },
    // { 
    //   title: 'Body Sizes', 
    //   content: (
    //     <p>This is the content for Body Sizes.</p>
    //   ),
    // },
    // { 
    //   title: 'Patreon', 
    //   content: (
    //     <p>This is the content for Patreon.</p>
    //   ),
    // },
    // Add more panels here as needed
  ];
  
  export default panelData;