// src/components/FilterPanel/FilterPanel.js

import React from 'react';
import './FilterPanel.css';
import arrowRightIcon from '../../assets/arrow_right_icon.png';
import arrowLeftIcon from '../../assets/arrow_left_icon.png';
import greenTickIcon from '../../assets/green_tick_icon.png';
import lockedIcon from '../../assets/locked_icon.png';
import patreonLogo from '../../assets/patreon_logo.png'; // Assuming you have this asset

const FilterPanel = ({
  showFilters,
  toggleFilterPanel,
  currentFilter,
  setCurrentFilter,
  postsPerLine,
  setPostsPerLine,
}) => {
  return (
    <div className={`filter-panel ${showFilters ? 'open' : 'closed'}`}>
      <button onClick={toggleFilterPanel} className="toggle-button">
        <img
          src={showFilters ? arrowLeftIcon : arrowRightIcon}
          alt="Toggle Filter Panel"
          className="toggle-icon"
        />
      </button>

      {/* Filter Buttons */}
      {showFilters && (
        <div className="filter-buttons">
          {/* Filter button for All Posts */}
          <button
            onClick={() => setCurrentFilter('all')}
            className={`filter-button ${currentFilter === 'all' ? 'active' : 'inactive'}`}
          >
            All
          </button>

          {/* Filter button for Unlocked Posts */}
          <button
            onClick={() => setCurrentFilter('unlocked')}
            className={`filter-button ${currentFilter === 'unlocked' ? 'active' : 'inactive'}`}
          >
            <img src={greenTickIcon} alt="Unlocked Icon" />
            Unlocked
          </button>

          {/* Filter button for Locked Posts */}
          <button
            onClick={() => setCurrentFilter('locked')}
            className={`filter-button ${currentFilter === 'locked' ? 'active' : 'inactive'}`}
          >
            <img src={lockedIcon} alt="Locked Icon" />
            Locked
          </button>

          {/* Filter button for Currently on Patreon */}
          <button
            onClick={() => setCurrentFilter('patreon')}
            className={`filter-button ${currentFilter === 'patreon' ? 'active' : 'inactive'}`}
          >
            <img src={patreonLogo} alt="Patreon Icon" className="patreon-icon" />
            Currently on Patreon
          </button>
        </div>
      )}

      {/* Posts per Line Selector */}
      {showFilters && (
        <div className="posts-per-line">
          <label htmlFor="posts-per-line-selector">Posts:</label>
          <select
            id="posts-per-line-selector"
            value={postsPerLine}
            onChange={(e) => setPostsPerLine(Number(e.target.value))}
          >
            {[2, 3, 4].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default FilterPanel;
