// src/services/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import ApiService from './ApiService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null means not checked yet
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Check authentication status on app load
    const checkAuthStatus = async () => {
      try {
        const response = await ApiService.fetchUserData();
        setIsAuthenticated(true);
        setUserData(response.data);
      } catch (error) {
        // If error, assume not authenticated
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, userData, setIsAuthenticated, setUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
