// src/pages/Admin.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import ApiService from '../services/ApiService';
import './Admin.css';
import Loader from '../components/Loader'; // Assuming you have a Loader component
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminPanel = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [updatedUser, setUpdatedUser] = useState(null);

  const [unlockedPosts, setUnlockedPosts] = useState([]);
  const [availablePosts, setAvailablePosts] = useState([]);
  const [postSearchTerm, setPostSearchTerm] = useState('');
  const [filteredAvailablePosts, setFilteredAvailablePosts] = useState([]);

  const [unlockedSearchTerm, setUnlockedSearchTerm] = useState('');

  const [newKoFiEmail, setNewKoFiEmail] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accountName, setAccountName] = useState('');

  const [loading, setLoading] = useState(false); // For loading indicators

  useEffect(() => {
    // Fetch user data to set authentication state
    ApiService.fetchUserData()
      .then((response) => {
        const user = response.data;
        if (user.isAdmin) {
          setIsLoggedIn(true);
          setAccountName(user.fullName || 'Admin');
          fetchUsers();
          fetchAllPosts();
        } else {
          navigate('/'); // Redirect non-admin users
        }
      })
      .catch((error) => {
        console.error('Error fetching user profile:', error);
        navigate('/'); // Redirect on error
      });
  }, [navigate]);

  const fetchUsers = () => {
    setLoading(true);
    ApiService.getUsers()
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
        toast.error('Failed to fetch users. Please try again later.');
        setLoading(false);
      });
  };

  useEffect(() => {
    const term = userSearchTerm.toLowerCase();
    setFilteredUsers(
      users.filter(
        (user) =>
          user.fullName.toLowerCase().includes(term) ||
          (user.email && user.email.toLowerCase().includes(term))
      )
    );
  }, [users, userSearchTerm]);

  const editUser = (patreonId) => {
    setLoading(true);
    ApiService.getUser(patreonId)
      .then((response) => {
        const user = response.data;
        setSelectedUser(user);
        setUpdatedUser({ ...user });
        fetchUnlockedPosts(user.unlocked_content || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching user:', error);
        if (error.response) {
          if (error.response.status === 404) {
            toast.error('User not found.');
          } else {
            toast.error(error.response.data.message || 'Error fetching user data.');
          }
        } else {
          toast.error('Network error. Please try again.');
        }
        setLoading(false);
      });
  };

  const handleInputChange = (field, value) => {
    setUpdatedUser({ ...updatedUser, [field]: value });
  };

  const saveUserChanges = () => {
    setLoading(true);
    ApiService.updateUser(updatedUser)
      .then((response) => {
        toast.success(response.data.message);
        fetchUsers();
        setSelectedUser(null);
        setUpdatedUser(null);
        setUnlockedPosts([]);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error updating user:', error);
        if (error.response) {
          toast.error(error.response.data.message || 'Error updating user.');
        } else {
          toast.error('Network error. Please try again.');
        }
        setLoading(false);
      });
  };

  const fetchUnlockedPosts = (unlockedContentIds) => {
    if (!unlockedContentIds.length) {
      setUnlockedPosts([]);
      return;
    }

    ApiService.getPostsByIds(unlockedContentIds)
      .then((response) => {
        setUnlockedPosts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching unlocked posts:', error);
        toast.error('Failed to fetch unlocked content. Please try again.');
      });
  };

  const fetchAllPosts = () => {
    ApiService.getAllPosts()
      .then((response) => {
        const posts = response.data || [];
        setAvailablePosts(posts);
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
        setAvailablePosts([]);
        toast.error('Failed to fetch available posts. Please try again.');
      });
  };

  useEffect(() => {
    if (Array.isArray(availablePosts) && updatedUser) {
      const unlockedIds = new Set(updatedUser.unlocked_content || []);
      const term = postSearchTerm.toLowerCase();

      const filtered = availablePosts.filter(
        (post) =>
          !unlockedIds.has(post.postId) &&
          (post.title.toLowerCase().includes(term) || post.postId.includes(term))
      );

      setFilteredAvailablePosts(filtered);
    } else {
      setFilteredAvailablePosts([]);
    }
  }, [availablePosts, updatedUser, postSearchTerm]);

  const addUnlockedContent = (postId) => {
    const updatedUnlockedContent = [
      ...(updatedUser.unlocked_content || []),
      postId,
    ];
    setUpdatedUser({ ...updatedUser, unlocked_content: updatedUnlockedContent });
    fetchUnlockedPosts(updatedUnlockedContent);
  };

  const removeUnlockedContent = (postId) => {
    if (window.confirm('Are you sure you want to remove this unlocked content?')) {
      const updatedUnlockedContent = (updatedUser.unlocked_content || []).filter(
        (id) => id !== postId
      );
      setUpdatedUser({ ...updatedUser, unlocked_content: updatedUnlockedContent });
      fetchUnlockedPosts(updatedUnlockedContent);
    }
  };

  const verifyKoFiEmail = (index) => {
    const updatedKoFiEmails = updatedUser.koFiEmails.map((emailObj, i) => {
      if (i === index) {
        return { ...emailObj, emailVerified: true };
      }
      return emailObj;
    });
    setUpdatedUser({ ...updatedUser, koFiEmails: updatedKoFiEmails });
  };

  const addKoFiEmail = () => {
    const email = newKoFiEmail.trim();
    if (email === '') {
      toast.error('Ko-fi email cannot be empty.');
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Invalid email format.');
      return;
    }
    // Proceed to add email
    const updatedKoFiEmails = [
      ...(updatedUser.koFiEmails || []),
      { email, emailVerified: false },
    ];
    setUpdatedUser({ ...updatedUser, koFiEmails: updatedKoFiEmails });
    setNewKoFiEmail('');
    toast.success('Ko-fi email added.');
  };

  return (
    <div className="admin-panel">
      <Navbar isLoggedIn={isLoggedIn} accountName={accountName} />
      <ToastContainer />
      <h1>Admin Panel</h1>
      <h2>User Management</h2>
      <div className="user-management-panel">
        <input
          type="text"
          placeholder="Search users..."
          className="search-bar"
          value={userSearchTerm}
          onChange={(e) => setUserSearchTerm(e.target.value)}
        />
        {loading ? (
          <Loader />
        ) : (
          <div className="user-list">
            {filteredUsers.map((user) => (
              <div key={user.patreonId} className="user-item">
                <span>{user.fullName} ({user.email})</span>
                <button onClick={() => editUser(user.patreonId)}>Edit</button>
              </div>
            ))}
          </div>
        )}
      </div>

      {selectedUser && updatedUser && (
        <div className="edit-user-panel">
          <h2>Edit User: {selectedUser.fullName}</h2>
          <div className="form-group">
            <label>
              <strong>Database ID (_id):</strong>
              <input
                type="text"
                value={updatedUser._id}
                readOnly
                disabled
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              <strong>Patreon ID:</strong>
              <input
                type="text"
                value={updatedUser.patreonId}
                readOnly
                disabled
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              <strong>Email:</strong>
              <input
                type="email"
                value={updatedUser.email || ''}
                onChange={(e) => handleInputChange('email', e.target.value)}
              />
            </label>
          </div>

          {/* Pledge History Section */}
          <div className="form-group pledge-history">
            <h3>Pledge History</h3>
            <div className="pledge-history-list">
              {updatedUser.pledge_history && updatedUser.pledge_history.length > 0 ? (
                <table className="pledge-history-table">
                  <thead>
                    <tr>
                      <th>Pledge ID</th>
                      <th>Amount</th>
                      <th>Date</th>
                      <th>Tier</th>
                      {/* Add other headers as necessary */}
                    </tr>
                  </thead>
                  <tbody>
                    {updatedUser.pledge_history.map((pledge) => (
                      <tr key={pledge.pledgeId || pledge.tier_id}>
                        <td>{pledge.pledgeId || 'N/A'}</td>
                        <td>${pledge.amount_cents / 100}</td>
                        <td>{new Date(pledge.date).toLocaleDateString()}</td>
                        <td>{pledge.tier_title}</td>
                        {/* Add other fields as necessary */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No pledge history available.</p>
              )}
            </div>
          </div>

          {/* Unlocked Content Section */}
          <div className="form-group unlocked-content">
            <h3>Unlocked Content</h3>
            <input
              type="text"
              placeholder="Search unlocked content..."
              className="search-bar"
              value={unlockedSearchTerm}
              onChange={(e) => setUnlockedSearchTerm(e.target.value)}
            />
            <div className="unlocked-content-list">
              {unlockedPosts
                .filter(
                  (post) =>
                    post.title.toLowerCase().includes(unlockedSearchTerm.toLowerCase()) ||
                    post.postId.includes(unlockedSearchTerm)
                )
                .map((post) => (
                  <div key={post.postId} className="unlocked-content-item">
                    <span>{post.postId} - {post.title}</span>
                    <button onClick={() => removeUnlockedContent(post.postId)}>Remove</button>
                  </div>
                ))}
            </div>
          </div>

          {/* Add Unlocked Content Section */}
          <div className="form-group add-unlocked-content">
            <h3>Add Unlocked Content</h3>
            <input
              type="text"
              placeholder="Search posts..."
              className="search-bar"
              value={postSearchTerm}
              onChange={(e) => setPostSearchTerm(e.target.value)}
            />
            <div className="available-posts-list">
              {filteredAvailablePosts.map((post) => (
                <div key={post.postId} className="available-post-item">
                  <span>{post.postId} - {post.title}</span>
                  <button onClick={() => addUnlockedContent(post.postId)}>Add</button>
                </div>
              ))}
            </div>
          </div>

          {/* Ko-fi Emails Section */}
          <div className="form-group">
            <h3>Ko-fi Emails</h3>
            <ul className="ko-fi-email-list">
              {updatedUser.koFiEmails &&
                updatedUser.koFiEmails.map((emailObj, index) => (
                  <li key={index} className="ko-fi-email-item">
                    <span>{emailObj.email} - {emailObj.emailVerified ? 'Verified' : 'Not Verified'}</span>
                    {!emailObj.emailVerified && (
                      <button onClick={() => verifyKoFiEmail(index)}>Verify</button>
                    )}
                  </li>
                ))}
            </ul>
            <input
              type="email"
              value={newKoFiEmail}
              onChange={(e) => setNewKoFiEmail(e.target.value)}
              placeholder="Enter Ko-fi email"
            />
            <button onClick={addKoFiEmail}>Add Ko-fi Email</button>
          </div>

          {/* Action Buttons */}
          <div className="form-group buttons">
            <button onClick={saveUserChanges} className="save-button">Save Changes</button>
            <button onClick={() => setSelectedUser(null)} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
