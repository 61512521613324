// src/services/PanelService.js

import axios from 'axios';
import config from '../pages/config.js';

const PanelService = {
  getPanels: async () => {
    const response = await axios.get(`${config.apiBaseUrl}/api/panels`);
    return response.data;
  },
  getExpandedContent: async (id) => {
    const response = await axios.get(`${config.apiBaseUrl}/api/panels/${id}/expanded`);
    return response.data;
  },
  createPanel: async (panelData) => {
    const response = await axios.post(`${config.apiBaseUrl}/api/panels`, panelData, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    return response.data;
  },
  updatePanel: async (id, panelData) => {
    const response = await axios.put(`${config.apiBaseUrl}/api/panels/${id}`, panelData, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    return response.data;
  },
  deletePanel: async (id) => {
    const response = await axios.delete(`${config.apiBaseUrl}/api/panels/${id}`, {
      withCredentials: true,
    });
    return response.data;
  },
  uploadPanelImage: async (panelId, imageFile) => {
    const formData = new FormData();
    formData.append('image', imageFile);

    const response = await axios.post(`${config.apiBaseUrl}/api/panelsupload/${panelId}/image`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true,
    });
    return response.data;
  },
};

export default PanelService;
