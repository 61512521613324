// src/pages/Login.js

import React, { useState, useEffect,useContext  } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import config from './config.js';
import './Login.css'; // Import the CSS file for styling
import Loader from '../components/Loader'; // Import Loader
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import { AuthContext } from '../services/AuthContext';
axios.defaults.withCredentials = true;

function Login() {
  const [kofiEmail, setKofiEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [linkedEmails, setLinkedEmails] = useState([]);
  const [unverifiedEmail, setUnverifiedEmail] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const { isAuthenticated, userData } = useContext(AuthContext);
  const { setIsAuthenticated, setUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true); // Start loading
      try {
        if (isAuthenticated === true) {
          const response = await axios.get(`${config.apiBaseUrl}/api/auth/user`);
          console.log('User data fetched:', response.data);
          setUserData(response.data);
  
          // Extract linked Ko-fi emails if available
          if (response.data.kofiEmails) {
            const verifiedEmails = response.data.kofiEmails.filter(
              (email) => email.emailVerified
            );
            const unverified = response.data.kofiEmails.find(
              (email) => !email.emailVerified
            );
            setLinkedEmails(verifiedEmails);
            setUnverifiedEmail(unverified || null);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast.error('Failed to fetch user data.');
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchUserData();
  }, []);

  const handlePatreonLogin = () => {
    window.location.href = `${config.apiBaseUrl}/api/auth/login`;
  };

  const handleLogout = async () => {
    setLoading(true); // Start loading
    try {
      await axios.get(`${config.apiBaseUrl}/api/auth/logout`);
      setUserData(null);
      setIsAuthenticated(false)
      navigate('/login');
      toast.success('Logged out successfully.');
    } catch (error) {
      console.error('Logout failed', error);
      navigate('/login');
      toast.error('Logout failed.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleLinkEmail = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/kofi/link`,
        { email: kofiEmail },
        { withCredentials: true }
      );
      console.log('Link email response:', response.data);
      toast.success('Verification code sent to your email.');
      // Update unverified email state
      setUnverifiedEmail({ email: kofiEmail });
      setKofiEmail('');
    } catch (error) {
      console.error('Error linking email:', error);
      toast.error(
        error.response?.data?.error || 'Failed to send verification code.'
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/kofi/verify-email`,
        { email: unverifiedEmail.email, code: verificationCode },
        { withCredentials: true }
      );
      console.log('Verify email response:', response.data);
      toast.success('Email verified and items claimed successfully.');
      setVerificationCode('');
      // Refresh user data to get updated linked emails
      const userResponse = await axios.get(`${config.apiBaseUrl}/api/auth/user`);
      setUserData(userResponse.data);
      if (userResponse.data.kofiEmails) {
        setLinkedEmails(
          userResponse.data.kofiEmails.filter((email) => email.emailVerified)
        );
        const unverified = userResponse.data.kofiEmails.find(
          (email) => !email.emailVerified
        );
        setUnverifiedEmail(unverified || null);
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      toast.error(
        error.response?.data?.error || 'Failed to verify email.'
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleCancelVerification = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/kofi/cancel-verification`,
        { email: unverifiedEmail.email },
        { withCredentials: true }
      );
      console.log('Cancel verification response:', response.data);
      setUnverifiedEmail(null);
      setVerificationCode('');
      toast.success('Verification process canceled.');
    } catch (error) {
      console.error('Error canceling verification:', error);
      toast.error(
        error.response?.data?.error || 'Failed to cancel verification.'
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleResendVerification = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/kofi/resend-verification`,
        { email: unverifiedEmail.email },
        { withCredentials: true }
      );
      console.log('Resend verification response:', response.data);
      toast.success('Verification code resent to your email.');
    } catch (error) {
      console.error('Error resending verification email:', error);
      toast.error(
        error.response?.data?.error || 'Failed to resend verification code.'
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // New function to navigate to Admin Page
  const navigateToAdminPage = () => {
    navigate('/admin');
  };

  return (
    <div className="login-page">
      {/* Add Navbar */}
      <Navbar isLoggedIn={!!userData} accountName={userData ? userData.fullName : ''} />
      <ToastContainer /> {/* Add ToastContainer */}

      {loading && <Loader />} {/* Display Loader when loading */}

      <div className="login-container">
        {userData ? (
          <div className="welcome-container">
            <h1>Welcome, {userData.fullName}</h1>
            <div className="user-info">
              <p>
                <strong>Email:</strong> {userData.email}
              </p>
              <p>
                <strong>Full Name:</strong> {userData.fullName}
              </p>
              <p>
                <strong>Is Admin:</strong> {userData.isAdmin ? 'Yes' : 'No'}
                {/* Add "To Admin Page" button next to "Is Admin: Yes" */}
                {userData.isAdmin && (
                  <button
                    className="admin-page-button"
                    onClick={navigateToAdminPage}
                  >
                    To Admin Page
                  </button>
                )}
              </p>

              {/* Display linked Ko-fi emails only if user is an admin */}
              {userData.isAdmin && (
                <>
                  <div className="linked-emails">
                    <h2>Linked Ko-fi Emails:</h2>
                    {linkedEmails.length > 0 ? (
                      <ul>
                        {linkedEmails.map((emailObj, index) => (
                          <li key={index}>
                            {emailObj.email} - Verified
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No Ko-fi emails linked yet.</p>
                    )}
                  </div>

                  {/* Unverified email panel */}
                  {unverifiedEmail && (
                    <div className="unverified-panel">
                      <h2>Unverified Email</h2>
                      <p>
                        You are verifying: <strong>{unverifiedEmail.email}</strong>
                      </p>
                      <p>
                        If you want to verify another email, you must cancel or finish this one first.
                      </p>
                      <form onSubmit={handleVerifyEmail}>
                        <input
                          type="text"
                          placeholder="Enter verification code"
                          value={verificationCode}
                          onChange={(e) => setVerificationCode(e.target.value)}
                          required
                        />
                        <button type="submit">Verify Email</button>
                      </form>
                      <button onClick={handleResendVerification}>Resend Verification Code</button>
                      <button onClick={handleCancelVerification}>Cancel Verification</button>
                      {/* Toast notifications will display status messages */}
                    </div>
                  )}

                  {/* Form to link new Ko-fi email */}
                  {!unverifiedEmail && (
                    <div className="link-email-form">
                      <h2>Link a New Ko-fi Email</h2>
                      <form onSubmit={handleLinkEmail}>
                        <input
                          type="email"
                          placeholder="Enter your Ko-fi email"
                          value={kofiEmail}
                          onChange={(e) => setKofiEmail(e.target.value)}
                          required
                        />
                        <button type="submit">Send Verification Code</button>
                      </form>
                      {/* Toast notifications will display status messages */}
                    </div>
                  )}
                </>
              )}
            </div>

            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        ) : (
          <div className="login-form-container">
            <h1>Login</h1>
            <p>Please log in with your Patreon account to continue.</p>
            <button className="patreon-login-button" onClick={handlePatreonLogin}>
              Login with Patreon
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
