import axios from 'axios';
import config from '../pages/config';

// Ensure Axios sends cookies with requests
axios.defaults.withCredentials = true;

const ApiService = {
  fetchPosts: () => {
    return axios.get(`${config.apiBaseUrl}/api/library/posts`);
  },

  fetchUserData: () => {
    return axios.get(`${config.apiBaseUrl}/api/auth/user`);
  },

  uploadImage: (postId, formData) => {
    return axios.post(`${config.apiBaseUrl}/api/upload/front/${postId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  updatePost: (postId, editedData) => {
    return axios.put(`${config.apiBaseUrl}/api/library/posts/${postId}`, editedData);
  },

  togglePost: (postId, enabled) => {
    return axios.put(`${config.apiBaseUrl}/api/library/posts/${postId}/toggle`, { enabled });
  },

  updateOrder: (postId, orderDate) => {
    return axios.put(`${config.apiBaseUrl}/api/library/posts/${postId}/order`, { orderDate });
  },
  // ADMIN ROUTES
  getTiers: () => {
    return axios.get(`${config.apiBaseUrl}/api/library/tiers`);
  },
  // ADMIN ROUTES
  getUsers: () => {
    return axios.get(`${config.apiBaseUrl}/api/admin/users`);
  },

  getUser: (patreonId) => {
    return axios.get(`${config.apiBaseUrl}/api/admin/users/${patreonId}`);
  },

  updateUser: (user) => {
    return axios.put(`${config.apiBaseUrl}/api/admin/users/${user.patreonId}`, user);
  },

  getPostsByIds: (postIds) => {
    return axios.post(`${config.apiBaseUrl}/api/admin/posts/getByIds`, { postIds });
  },
  
  // New method to fetch all posts with 'postId' and 'title'
  getAllPosts: () => {
    return axios.get(`${config.apiBaseUrl}/api/admin/posts`);
  },
};

export default ApiService;
