// src/components/NsfwWarning.js
import React, { useEffect, useState } from 'react';
import './NsfwWarning.css';

const NsfwWarning = ({ onAccept }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem('nsfwAccepted');
    if (!isAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('nsfwAccepted', 'true');
    setIsVisible(false);
    onAccept();
  };

  if (!isVisible) return null;

  return (
    <div className="nsfw-warning-overlay">
      <div className="nsfw-warning-modal">
        <h2>Warning: NSFW Content</h2>
        <p>Some content on this page may be considered NSFW. By proceeding, you confirm that you are at least 18 years old.</p>
        <button onClick={handleAccept}>I am 18+</button>
      </div>
    </div>
  );
};

export default NsfwWarning;
